'use strict';
import SettingsUtility from "@/modules/core/utility/SettingsUtility";
import Vue from "vue";
import {Store} from "vuex";
import {MoodleApi} from "@/modules/core/webservice/moodle-api";
import ErrorUtility from "@/modules/core/utility/ErrorUtility";

class UserUtility {
    static hasCourseCreatorDefaultRole(store?: Store<any>): boolean {
        if (store instanceof Store) {
            return store.getters['login/GET_USER_DEFAULT_ROLE'] === SettingsUtility.getSetting("core.roles.coursecreator")
        }

        if (Vue.prototype.$customStore instanceof Store) {
            return Vue.prototype.$customStore.getters['login/GET_USER_DEFAULT_ROLE'] === SettingsUtility.getSetting("core.roles.coursecreator")
        }

        return false
    }

    static async hasEditCourseCapabilities(courseId: number): Promise<boolean> {
        if (!(Vue.prototype.$customStore instanceof Store)) {
            return false
        }

        const userId = Vue.prototype.$customStore.getters['login/GET_USER_ID']
        const userCapabilities = await MoodleApi.get('core_user_get_course_user_profiles', {
            "userlist[0][userid]": userId,
            "userlist[0][courseid]": courseId
        })
        ErrorUtility.parseResponse(userCapabilities)
        if (ErrorUtility.hasError()) {
            return false
        }

        let hasEditCourseCapability = false
        userCapabilities[0].roles.forEach((role: any) => {
            if (role.roleid === SettingsUtility.getSetting("core.roles.editingTeacher")) {
                hasEditCourseCapability = true
            }
        })

        return hasEditCourseCapability
    }
}

export default UserUtility;