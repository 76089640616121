
import ErrorUtility from "@/modules/core/utility/ErrorUtility";
import {Component, Vue} from 'vue-property-decorator'
import {MoodleApi} from "@/modules/core/webservice/moodle-api";
import UserUtility from "@/modules/core/utility/UserUtility";

@Component<DashboardIntro>({
    data() {
        return {
            hasCourseCreatorDefaultRole: UserUtility.hasCourseCreatorDefaultRole()
        }
    }
})
export default class DashboardIntro extends Vue {

    user = null
    alert = false
    alertType = 'error'
    alertMessage = ''
    currentDate = function() {
        let date = new Date(),
            day: any = date.getDate(),
            month: any = date.getMonth();

        month = month + 1;

        if (String(day).length == 1) {
            day = '0' + day;
        }

        if (String(month).length == 1) {
            month = '0' + month;
        }

        return day+ '.' + month + '.' + date.getFullYear();
    }

    async mounted()
    {
        await this.loadUser()
    }

    async loadUser()
    {
        this.user = await MoodleApi.get('local_lumaconi_get_user', {})

        ErrorUtility.parseResponse(this.user)
        if(ErrorUtility.hasError()) {
            this.alert = true
            this.alertType = 'error'
            this.alertMessage = ErrorUtility.getComputedErrorMessage()
        }

    }
}
