'use strict';

class ErrorUtility {
    static error:any = null

    static parseResponse(response: any) {
        this.error = response
        return this
    }

    static hasError() {
        if(this.error && this.error.errorcode) {
            return true
        }
        return false
    }

    static getErrorCode() {
        if(this.error && this.error.errorcode) {
            return this.error.errorcode
        }
        return ''
    }

    static getErrorMessage() {
        if(this.error && this.error.message) {
            return this.error.message
        }
        return ''
    }

    static getComputedErrorMessage() {
        return this.getErrorCode() + ': ' + this.getErrorMessage()
    }
}

export default ErrorUtility;