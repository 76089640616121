
import SettingsUtility from "@/modules/core/utility/SettingsUtility";
import ErrorUtility from "@/modules/core/utility/ErrorUtility";
import {Component, Vue} from "vue-property-decorator";
import {MoodleApi} from "@/modules/core/webservice/moodle-api";
import {QuizResponse} from "@/modules/quiz/models/response/QuizResponse"
import {Quiz, QuizAccessInformation, QuizBestGrade} from "@/modules/quiz/models/Quiz"
import {
    Choice,
    ChoiceGroup,
    DragDropImageOrTextQuestion,
    EssayQuestion, ImageFile,
    MultichoiceAnswer,
    MultichoiceQuestion, Place,
    QuizQuestion
} from "@/modules/quiz/models/response/QuizQuestion";
import {AttemptResponse} from "@/modules/course/models/response/AttemptResponse"
import {
    AttemptAccessInformation,
    AttemptReview,
    AttemptSummary,
    AttemptSummaryQuestion,
    UserAttempt
} from "@/modules/quiz/models/Attempt";
import UserUtility from "@/modules/core/utility/UserUtility";
import {AttemptReviewResponse} from "@/modules/quiz/models/response/QuizAttemptResponse";
import DashboardIntro from "@/modules/core/components/modules/DashboardIntro.vue";
import {GET_TOKEN} from "@/modules/login/store/getters";
import {namespace} from "vuex-class";
import { Drag, Drop } from "vue-easy-dnd";

const login = namespace('login')

// @ts-ignore: Vuetify error
@Component({
    components: {
        DashboardIntro,
        AppSkeleton: SettingsUtility.getSetting("core.components.appSkeleton"),
        Notification: SettingsUtility.getSetting("core.components.notification"),
        TukBreadcrumb: SettingsUtility.getSetting('core.components.components.tukBreadcrumb'),
        quizNavigation: SettingsUtility.getSetting("quiz.components.components.quizNavigation"),
        quizSummary: SettingsUtility.getSetting("quiz.components.components.quizSummary"),
        quizReview: SettingsUtility.getSetting("quiz.components.components.quizReview"),
        Drag,
        Drop
    },
    data() {
        return {
            hasCourseCreatorDefaultRole: UserUtility.hasCourseCreatorDefaultRole()
        }
    }
})
export default class QuizDetail extends Vue {
    static QUIZ_GRADE_HIGHEST = 1;
    static QUIZ_GRADE_AVERAGE = 2;
    static QUIZ_ATTEMPT_FIRST = 3;
    static QUIZ_ATTEMPT_LAST = 4;

    static QUIZ_ATTEMPT_STATUS_IN_PROGRESS = 'inprogress'
    static QUIZ_ATTEMPT_STATUS_FINISHED = 'finished'

    courseId = 0
    id = 0
    attemptId = 0
    currentPage = 0
    continueAttempt = false
    attemptStarted = false
    attemptFinished = false
    notification = {}
    notificationActive = false
    quiz: Quiz | null = null
    quizQuestions: any = []
    attemptData: AttemptResponse | null = null
    quizAccessInformation: QuizAccessInformation | null = null
    attemptAccessInformation: AttemptAccessInformation | null = null
    userAttempts: UserAttempt[] = []
    allUserAttempts: UserAttempt[] = []
    allAttemptsForTeacher: UserAttempt[] = []
    attemptSummary: AttemptSummary = {
        questions: []
    }
    attemptReview: AttemptReview | null = null
    summaryVisible = false
    attemptReviewVisible = false

    allowEditing: boolean = false

    bestGrade: number | null = null

    maxLengthTextChoice: number = 0

    @login.Getter(GET_TOKEN) token: number | undefined

    get crumbs() {
        return [
            {
                text: 'Dashboard',
                disabled: false,
                to: '/',
            },
            {
                text: 'Situationen',
                disabled: false,
                to: '/course/list/',
            },
            {
                text: 'Handlungssituation',
                disabled: false,
                to: '/course/detail/' + this.courseId,
            },
            {
                text: 'Quiz',
                disabled: true,
                to: '/',
            }
        ]
    }

    async created() {
        this.courseId = parseInt(this.$route.params.courseId)
        this.id = parseInt(this.$route.params.id)
        this.allowEditing = await UserUtility.hasEditCourseCapabilities(this.courseId)

        await this.getQuiz()
        await this.triggerViewEvent()

        if (this.quiz) {
            await this.getQuizAccessInformation()
            await this.getAttemptAccessInformation()
        }

        //set user attempts
        await this.setUserAttempts()
        await this.getAllUserAttempts()
        await this.getUserBestGrade()
        await this.getAllAttemptsForTeacher()

        if (this.quiz === null || this.attemptAccessInformation === null || this.attemptAccessInformation.isfinished) {
            this.notification = {
                type: 'error',
                message: 'Das Quiz ist bereits geschlossen, es kann kein neuer Versuch gestartet werden.'
            }
            this.notificationActive = true
        }
    }

    async getQuiz() {
        const quizResponse = await MoodleApi.get('mod_quiz_get_quizzes_by_courses', {
            courseids: [this.courseId]
        })

        ErrorUtility.parseResponse(quizResponse)
        if (ErrorUtility.hasError()) {
            this.notification = quizResponse
            this.notificationActive = true
        } else {
            quizResponse.quizzes.forEach((quizResponse: QuizResponse): void => {
                if (quizResponse.id === this.id) {
                    this.quiz = {
                        id: quizResponse.id,
                        description: quizResponse.intro,
                        name: quizResponse.name,
                        exam: quizResponse.exam ?? false,
                        visible: quizResponse.visible,
                        section: quizResponse.section,
                        timeOpen: quizResponse.timeopen ?? 0,
                        timeClose: quizResponse.timeclose ?? 0,
                        decimalPoints: quizResponse.decimalpoints ?? 2,
                        sumGrades: quizResponse.sumgrades ?? 0,
                        grade: quizResponse.grade,
                        gradePass: quizResponse.completionpass,
                        gradeMethod: quizResponse.grademethod,
                        attempts: quizResponse.attempts,
                        shuffleAnswers: !!quizResponse.shuffleanswers,
                        preferredBehaviour: quizResponse.preferredbehaviour,
                        attemptOnLast: !!quizResponse.attemptonlast,
                        feedback: '',
                        additionalFeedback: []
                    }
                }
            })
        }
    }

    async getQuizAccessInformation() {
        const quizAccessInformationResponse = await MoodleApi.get('mod_quiz_get_quiz_access_information', {
            quizid: this.quiz?.id
        }) as QuizAccessInformation

        if (ErrorUtility.hasError()) {
            this.notification = quizAccessInformationResponse
            this.notificationActive = true
        } else {
            this.quizAccessInformation = quizAccessInformationResponse
        }
    }

    async getAttemptAccessInformation() {
        const attemptAccessInformationResponse = await MoodleApi.get('mod_quiz_get_attempt_access_information', {
            quizid: this.quiz?.id,
            attemptid: this.attemptId
        }) as AttemptAccessInformation

        if (ErrorUtility.hasError()) {
            this.notification = attemptAccessInformationResponse
            this.notificationActive = true
        } else {
            this.attemptAccessInformation = attemptAccessInformationResponse
        }
    }

    async setUserAttempts() {
        const userAttemptsResponse = await MoodleApi.get('mod_quiz_get_user_attempts', {
            quizid: this.id,
            status: 'unfinished',
            includepreviews: 1
        })

        if (ErrorUtility.hasError()) {
            this.notification = userAttemptsResponse
            this.notificationActive = true
        } else {
            if (userAttemptsResponse.attempts) {
                userAttemptsResponse.attempts.forEach((userAttempt: UserAttempt): void => {
                    this.userAttempts.push(userAttempt)

                    if (userAttempt.state === "inprogress") {
                        this.attemptId = userAttempt.id
                        this.currentPage = userAttempt.currentpage
                        this.continueAttempt = true
                    }
                })
            }
        }
    }

    async getAllUserAttempts() {
        const userAttemptsResponse = await MoodleApi.get('mod_quiz_get_user_attempts', {
            quizid: this.id,
            status: 'all',
            includepreviews: 1
        })

        if (ErrorUtility.hasError()) {
            this.notification = userAttemptsResponse
            this.notificationActive = true
        } else {
            if (userAttemptsResponse.attempts) {
                for (const userAttempt of userAttemptsResponse.attempts) {
                    userAttempt.feedbacktext = await this.getQuizFeedbackForGrade(userAttempt.sumgrades)
                    this.allUserAttempts.push(userAttempt);
                }
            }
        }
    }

    async fetchQuizAttemptData(attemptId: number, page: number) {
        const attemptDataResponse = await MoodleApi.get('local_lumaconi_get_quiz_attempt_data', {
            attempt_id: attemptId,
            page: page
        })

        ErrorUtility.parseResponse(attemptDataResponse)
        if (ErrorUtility.hasError()) {
            this.notificationActive = true
            this.notification = attemptDataResponse
        } else {
            this.quizQuestions = []

            //TODO: define attempt response interfaces
            attemptDataResponse.questions.forEach((attemptDataQuestion: any) => {
                this.fetchQuestion(attemptDataQuestion.type, attemptDataQuestion.id, attemptDataQuestion.course_id)
            })
            this.attemptData = attemptDataResponse
        }
    }

    async fetchQuestion(questionType: string, questionId: number, courseId: number) {
        const questionResponse = await MoodleApi.get('local_lumaconi_get_qtype_' + questionType, {
            quiz_id: this.quiz?.id,
            question_id: questionId,
            course_id: courseId
        })

        ErrorUtility.parseResponse(questionResponse)
        if (ErrorUtility.hasError()) {
            this.notificationActive = true
            this.notification = questionResponse
        } else {
            const questionData: any = this.attemptData!.questions!.find((question: any) => question.id === questionId)
            if(questionData) {
                if (questionType === 'essay') {
                    const question = {
                        id: questionResponse.id,
                        type: questionType,
                        number: questionData.number,
                        text: questionResponse.question_text,
                        answer: questionResponse.answer,
                        answerFormat: questionResponse.answer_format,
                        sequenceCheck: questionData.sequence_check,
                        status: questionData.status,
                        marked: questionData.flagged,
                        uniqueId: this.userAttempts[0].uniqueid,
                        defaultMark: questionResponse.default_mark
                    } as EssayQuestion
                    this.quizQuestions.push(question)
                }

                if (questionType === 'multichoice') {
                    let answers: MultichoiceAnswer[] = []

                    for (const answer of questionResponse.answers) {
                        let answerObject: MultichoiceAnswer = {
                            id: answer.id,
                            answer: this.parseTextWithImage(answer.answer),
                            answerFormat: answer.answer_format,
                            feedback: answer.feedback,
                            feedbackFormat: answer.feedback_format,
                            checked: answer.checked,
                        }
                        answers.push(answerObject)
                    }

                    const question = {
                        id: questionResponse.id,
                        type: questionType,
                        number: questionData.number,
                        text: this.parseTextWithImage(questionResponse.question_text),
                        answersCount: questionResponse.answers_count,
                        answers: answers,
                        single: questionResponse.single,
                        singleAnswer: questionResponse.single_answer,
                        sequenceCheck: questionData.sequence_check,
                        status: questionData.status,
                        marked: questionData.flagged,
                        uniqueId: this.userAttempts[0].uniqueid,
                        defaultMark: questionResponse.default_mark
                    } as MultichoiceQuestion
                    this.quizQuestions.push(question)
                }

                if (questionType === 'ddimageortext') {
                    let backgroundImage: ImageFile | null = null

                    for (const image of questionResponse.background_images) {
                        backgroundImage = {
                            fileName: image.filename,
                            filePath: image.filepath,
                            fileSize: image.filesize,
                            fileUrl: image.fileurl + '?token=' + this.token,
                            isExternalFile: image.isexternalfile,
                            mimeType: image.mimetype,
                            timeModified: image.timemodified
                        }
                        break
                    }

                    for (const choiceGroupItems of questionResponse.choices) {
                        for (const choiceItem of choiceGroupItems) {
                            let image: ImageFile | null = null
                            for (const imageObject of choiceItem.image) {
                                image = {
                                    fileName: imageObject.filename,
                                    filePath: imageObject.filepath,
                                    fileSize: imageObject.filesize,
                                    fileUrl: imageObject.fileurl + '?token=' + this.token,
                                    isExternalFile: imageObject.isexternalfile,
                                    mimeType: imageObject.mimetype,
                                    timeModified: imageObject.timemodified
                                }
                                break
                            }

                            if (!image) {
                                if (this.maxLengthTextChoice < choiceItem.text.length) {
                                    this.maxLengthTextChoice = choiceItem.text.length
                                }
                            }
                        }
                    }

                    let choiceGroupsArray = []
                    for (const choiceGroupItems of questionResponse.choices) {
                        let choicesArray = []
                        for (const choiceItem of choiceGroupItems) {
                            let image: ImageFile | null = null
                            for (const imageObject of choiceItem.image) {
                                image = {
                                    fileName: imageObject.filename,
                                    filePath: imageObject.filepath,
                                    fileSize: imageObject.filesize,
                                    fileUrl: imageObject.fileurl + '?token=' + this.token,
                                    isExternalFile: imageObject.isexternalfile,
                                    mimeType: imageObject.mimetype,
                                    timeModified: imageObject.timemodified
                                }
                                break
                            }

                            let choice: Choice = {
                                id: choiceItem.id,
                                group: choiceItem.group,
                                no: choiceItem.no,
                                no_in_group: choiceItem.no_in_group,
                                is_dragged: choiceItem.is_dragged,
                                text: choiceItem.text,
                                infinite: choiceItem.infinite,
                                image: image,
                                text_width: this.resizeAllDragsAndDrops()
                            }
                            choicesArray.push(choice)
                        }

                        let choiceGroup: ChoiceGroup = {
                            choices: choicesArray
                        }
                        choiceGroupsArray.push(choiceGroup)
                    }

                    const question = {
                        id: questionResponse.id,
                        type: questionType,
                        number: questionData.number,
                        text: questionResponse.question_text,
                        backgroundImage: backgroundImage,
                        choices: choiceGroupsArray,
                        places: questionResponse.places,
                        sequenceCheck: questionData.sequence_check,
                        status: questionData.status,
                        marked: questionData.flagged,
                        uniqueId: this.userAttempts[0].uniqueid,
                        defaultMark: questionResponse.default_mark
                    } as DragDropImageOrTextQuestion
                    this.quizQuestions.push(question)
                }
            }
        }
    }

    getNextPage(): void {
        if (this.attemptData) {
            this.processAttempt()
            if (this.attemptData.next_page && this.attemptData.next_page === -1) {
                return
            }
            this.currentPage++
            this.fetchQuizAttemptData(this.attemptData.attempt.id, this.currentPage)
            this.triggerViewAttemptEvent()
        }
    }

    getPreviousPage(): void {
        if (this.attemptData) {
            this.processAttempt()
            if (this.currentPage === 0) {
                return
            }
            this.currentPage--
            this.fetchQuizAttemptData(this.attemptData.attempt.id, this.currentPage)
            this.triggerViewAttemptEvent()
        }
    }

    getPage(page: number) {
        if (this.attemptData) {
            this.processAttempt();
            this.fetchQuizAttemptData(this.attemptData.attempt.id, page);
            this.currentPage = page;
        }
    }

    nextHandler(page: number) {
        if(page && page === -1) {
            this.showSummary()
        } else {
            this.getNextPage()
        }
    }

    async showSummary() {
        await this.processAttempt()

        const attemptSummary = await this.getAttemptSummary()
        if(attemptSummary) {
            this.attemptSummary.questions = attemptSummary.questions
            this.summaryVisible = true
        }

        await this.triggerViewAttemptSummeryEvent()
    }

    async finishAttempt() {
        await this.processAttempt(true)

        const attemptReviewResponse = await MoodleApi.get('mod_quiz_get_attempt_review', {
            attemptid: this.attemptId,
            page: -1,
            moodlewssettingfilter: true,
            moodlewssettingfileurl: true
        }) as AttemptReviewResponse

        ErrorUtility.parseResponse(attemptReviewResponse)
        if (ErrorUtility.hasError()) {
            this.notificationActive = true
            this.notification = attemptReviewResponse
        } else {
            const attemptReview = {
                state: attemptReviewResponse.attempt.state,
                sumgrades: attemptReviewResponse.attempt.sumgrades,
                timecheckstate: attemptReviewResponse.attempt.timecheckstate,
                timefinish: attemptReviewResponse.attempt.timefinish,
                timemodified: attemptReviewResponse.attempt.timemodified,
                timemodifiedoffline: attemptReviewResponse.attempt.timemodifiedoffline,
                timestart: attemptReviewResponse.attempt.timestart,
                questions: attemptReviewResponse.questions.map((question: any) => {
                    question['specificFeedback'] = this.getFeedback(question.html)
                    question['formulation'] = this.getFormulation(question.html)
                    question['questionText'] = this.getQuestionText(question.html)
                    question['essayAnswerText'] = this.getEssayAnswerText(question.html)
                    question['multiChoiceRightAnswer'] = this.getMultiChoiceRightAnswer(question.html)
                    return question;
                })
            } as AttemptReview

            this.attemptReview = attemptReview
            this.attemptReviewVisible = true
            this.summaryVisible = false
            this.attemptFinished = true

            await this.triggerViewAttemptReviewEvent()
        }
    }

    async startQuizAttempt(forceNewAttempt: boolean = true) {
        if (this.quizAccessInformation && !this.quizAccessInformation.canattempt) {
            this.notificationActive = true
            this.notification = {
                type: 'error',
                message: 'User cannot attempt.'
            }
            return
        }

        const startAttemptResponse = await MoodleApi.get('mod_quiz_start_attempt', {
            quizid: this.id,
            forcenew: forceNewAttempt ? 1 : 0 // force new attempt
        })

        ErrorUtility.parseResponse(startAttemptResponse)
        if (ErrorUtility.hasError()) {
            this.notificationActive = true
            this.notification = startAttemptResponse
            return
        }

        //set user attempts
        await this.setUserAttempts()

        const userAttemptsResponse = await MoodleApi.get('mod_quiz_get_user_attempts', {
            quizid: this.quiz!.id,
            status: 'unfinished',
            includepreviews: 1
        })

        this.attemptStarted = true
        this.attemptData = startAttemptResponse
        this.attemptId = startAttemptResponse.attempt.id
        this.currentPage = startAttemptResponse.attempt.currentpage

        const attemptSummary = await this.getAttemptSummary()
        if(attemptSummary) {
            this.attemptSummary.questions = attemptSummary.questions
        }

        await this.fetchQuizAttemptData(this.attemptId, this.currentPage)
        await this.triggerViewAttemptEvent()
    }

    async continueQuizAttempt() {
        this.attemptStarted = true

        if (this.quizAccessInformation && this.quizAccessInformation.canattempt) {
            const attemptSummary = await this.getAttemptSummary()
            if (attemptSummary) {
                this.attemptSummary.questions = attemptSummary.questions
            }
            await this.fetchQuizAttemptData(this.userAttempts[0].id, this.currentPage)
            await this.triggerViewAttemptEvent()
        }
    }

    async processAttempt(finishAttempt: boolean = false, attachData = true) {
        let data: any = []
        let counter = 0

        if (this.quizQuestions.length > 0 && !finishAttempt && attachData) {
            this.quizQuestions.forEach((question: QuizQuestion) => {
                // _:sequencecheck
                data[`data[${counter}][name]`] = `q${this.userAttempts[0].uniqueid}:${question.number}_:sequencecheck`
                data[`data[${counter}][value]`] = question.sequenceCheck
                counter++

                if (question.type === 'essay') {
                    const essayQuestion = question as EssayQuestion

                    // _answerformat
                    data[`data[${counter}][name]`] = `q${this.userAttempts[0].uniqueid}:${question.number}_answerformat`
                    data[`data[${counter}][value]`] = essayQuestion.answerFormat
                    counter++

                    // _answer
                    data[`data[${counter}][name]`] = `q${this.userAttempts[0].uniqueid}:${question.number}_answer`
                    data[`data[${counter}][value]`] = essayQuestion.answer
                    counter++
                }

                if (question.type === 'multichoice') {
                    const multichoiceQuestion = question as MultichoiceQuestion

                    if (multichoiceQuestion.single) {
                        // answer
                        data[`data[${counter}][name]`] = `q${this.userAttempts[0].uniqueid}:${question.number}_answer`
                        data[`data[${counter}][value]`] = multichoiceQuestion.singleAnswer
                        counter++
                    } else {
                        multichoiceQuestion.answers.forEach((answer, index) => {
                            // _choiceX
                            data[`data[${counter}][name]`] = `q${this.userAttempts[0].uniqueid}:${question.number}_choice${index}`
                            data[`data[${counter}][value]`] = answer.checked
                            counter++
                        })
                    }
                }

                if (question.type === 'ddimageortext') {
                    const dragDropImageOrTextQuestion = question as DragDropImageOrTextQuestion

                    dragDropImageOrTextQuestion.places.forEach((place, index) => {
                        // _pX
                        data[`data[${counter}][name]`] = `q${this.userAttempts[0].uniqueid}:${question.number}_p${place.no}`
                        data[`data[${counter}][value]`] = place.selected_choice
                        counter++
                    })
                }
            })
        }

        const response = await MoodleApi.get('mod_quiz_process_attempt', Object.assign({},
            {
                'attemptid': this.attemptId,
                'finishattempt': finishAttempt && finishAttempt === true ? 1 : 0,
                'timeup': 0,
                'preflightdata': [],
                'moodlewssettingfilter': 1,
                'moodlewssettingfileurl': 1
            },
            !finishAttempt ? data : {}
        ))

        ErrorUtility.parseResponse(response)
        if (ErrorUtility.hasError()) {
            this.notificationActive = true
            this.notification = response
        }
    }

    async getAttemptSummary()  {
        const response: AttemptSummary = await MoodleApi.get('mod_quiz_get_attempt_summary',
            {
                'attemptid': this.attemptId,
                'preflightdata': []
            }
        )

        ErrorUtility.parseResponse(response)
        if (ErrorUtility.hasError()) {
            this.notificationActive = true
            this.notification = response
            return false;
        } else {
            const attemptSummary: AttemptSummary = {
                questions: []
            }
            if (response.questions.length > 0) {
                response.questions.forEach((question: AttemptSummaryQuestion) => {
                    attemptSummary.questions.push({
                        slot: question.slot,
                        number: question.number,
                        status: question.status,
                        type: question.type,
                        page: question.page
                    })
                })
            }
            return attemptSummary
        }
    }

    async getUserBestGrade() {
        const response = await MoodleApi.get('mod_quiz_get_user_best_grade', {
            quizid: this.id,
            userid: 0
        }) as QuizBestGrade

        ErrorUtility.parseResponse(response)
        if (ErrorUtility.hasError()) {
            this.notification = response
            this.notificationActive = true
        } else {
            if (response.hasgrade) {
                this.bestGrade = response.grade
            }
        }
    }

    async getQuizFeedbackForGrade(grade: number | null) {
        if (grade === null) {
            return ''
        }

        const response = await MoodleApi.get('mod_quiz_get_quiz_feedback_for_grade', {
            quizid: this.id,
            grade: grade
        })

        ErrorUtility.parseResponse(response)
        if (ErrorUtility.hasError()) {
            this.notification = response
            this.notificationActive = true
        } else {
            if (response.feedbacktext) {
                return response.feedbacktext
            }
        }

        return ''
    }

    async getAllAttemptsForTeacher() {
        const response = await MoodleApi.get('local_lumaconi_get_all_quiz_attempts', {
            quiz_id: this.id,
        })

        if (ErrorUtility.hasError()) {
            this.notification = response
            this.notificationActive = true
        } else {
            if (response.attempts) {
                for (const attempt of response.attempts) {
                    this.allAttemptsForTeacher.push(attempt);
                }
            }
        }
    }

    async triggerViewEvent() {
        const response = await MoodleApi.get('mod_quiz_view_quiz', {
            quizid: this.id
        })

        ErrorUtility.parseResponse(response)
        if (ErrorUtility.hasError()) {
            this.notification = response
            this.notificationActive = true
        }
    }

    async triggerViewAttemptSummeryEvent() {
        const response = await MoodleApi.get('mod_quiz_view_attempt_summary', {
            attemptid: this.attemptId
        })

        ErrorUtility.parseResponse(response)
        if (ErrorUtility.hasError()) {
            this.notification = response
            this.notificationActive = true
        }
    }

    async triggerViewAttemptReviewEvent() {
        const response = await MoodleApi.get('mod_quiz_view_attempt_review', {
            attemptid: this.attemptId
        })

        ErrorUtility.parseResponse(response)
        if (ErrorUtility.hasError()) {
            this.notification = response
            this.notificationActive = true
        }
    }

    async triggerViewAttemptEvent() {
        const response = await MoodleApi.get('mod_quiz_view_attempt', {
            attemptid: this.attemptId,
            page: this.currentPage
        })

        ErrorUtility.parseResponse(response)
        if (ErrorUtility.hasError()) {
            this.notification = response
            this.notificationActive = true
        }
    }

    getFeedback(htmlString: string){
        const selector = `.specificfeedback`;
        return this.getTextNode(htmlString, selector);
    }

    getFormulation(htmlString: string){
        const selector = `.formulation`;
        return this.getTextNode(htmlString, selector);
    }

    getQuestionText(htmlString: string){
        const selector = `.qtext`;
        return this.getTextNode(htmlString, selector);
    }

    getEssayAnswerText(htmlString: string){
        const selector = `.qtype_essay_response`;
        return this.getTextNode(htmlString, selector);
    }

    getMultiChoiceRightAnswer(htmlString: string){
        const selector = `.rightanswer`;
        return this.getTextNode(htmlString, selector);
    }

    getTextNode(htmlString: string, selector: string) {
        if(htmlString && selector) {
            const parser = new DOMParser();
            const docFragment = parser.parseFromString(htmlString, "application/xml");
            let textNode = '';

            try {
                textNode = docFragment.querySelector(selector)!.textContent || '';
            } catch (error) {
                console.warn(error);
            }
            return textNode;
        }
    }

    reloadView() {
        this.$emit('forceRerenderView');
    }

    getHumanReadableAttemptPoints(grade: number | null): string {
        if (grade === null) {
            return 'Bisher nicht bewertet'
        }

        return grade.toFixed(this.quiz?.decimalPoints)
    }

    getHumanReadableAttemptGrade(grade: number | null): string {
        if (grade === null) {
            return 'Bisher nicht bewertet'
        }

        if (this.quiz?.sumGrades && this.quiz.sumGrades > 0) {
            return ((grade / this.quiz?.sumGrades) * 100).toFixed(this.quiz.decimalPoints)
        }

        return 'Bewertung nicht möglich'
    }

    getHumanReadableGradeMethod(gradeMethod: number): string {
        if (gradeMethod === QuizDetail.QUIZ_GRADE_HIGHEST) {
            return 'Bester Versuch'
        }
        if (gradeMethod === QuizDetail.QUIZ_GRADE_AVERAGE) {
            return 'Durchschnitt'
        }
        if (gradeMethod === QuizDetail.QUIZ_ATTEMPT_FIRST) {
            return 'Erster Versuch'
        }
        if (gradeMethod === QuizDetail.QUIZ_ATTEMPT_LAST) {
            return 'Letzter vollständiger Versuch'
        }
        return '-'
    }

    getHumanReadableAttemptStatus(state: string): string {
        if (state === QuizDetail.QUIZ_ATTEMPT_STATUS_IN_PROGRESS) {
            return 'In Bearbeitung'
        }
        if (state === QuizDetail.QUIZ_ATTEMPT_STATUS_FINISHED) {
            return 'Beendet'
        }
        return '-'
    }

    getFormattedDateByTimestamp(timestamp: number): string {
        let date = new Date(timestamp * 1000)
        return this.getFormattedDate(date)
    }

    getFormattedDate(date: Date): string {
        if (typeof date === 'undefined') {
            return ''
        }

        const days = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
        const dayOfWeek = days[date.getDay()]

        const year = date.getFullYear()

        let month = (1 + date.getMonth()).toString()
        month = month.length > 1 ? month : '0' + month

        let day = date.getDate().toString()
        day = day.length > 1 ? day : '0' + day

        let hours = date.getHours().toString()
        hours = hours.length > 1 ? hours : '0' + hours

        let minutes = date.getMinutes().toString()
        minutes = minutes.length > 1 ? minutes : '0' + minutes

        return dayOfWeek + ', ' + day + '.' + month + '.' + year + ', ' + hours + ':' + minutes
    }

    parseTextWithImage(text: string) {
        let parser = new DOMParser();
        let htmlDoc = parser.parseFromString(text, 'text/html');

        let imgTags = htmlDoc.getElementsByTagName('img');

        for (let item of imgTags) {
            let src = item.getAttribute('src')
            src = src + '?token=' + this.token
            item.setAttribute('src', src)
        }

        return htmlDoc.body.innerHTML
    }

    resetDnD(question: DragDropImageOrTextQuestion) {
        for (let choiceGroupItem of question.choices) {
            for (let choice of choiceGroupItem.choices) {
                choice.is_dragged = false
            }
        }

        for (let place of question.places) {
            place.selected_choice = 0
        }
    }

    onImageOrTextDrop(event: any) {
        let questionId = event.top.$attrs['data-question-id']
        let placeNumber = event.top.$attrs['data-place-no']
        let placeGroup = event.top.$attrs['data-place-group']
        let choiceGroup = event.source.$attrs['data-choice-group']
        let choiceNumberInGroup = event.source.$attrs['data-choice-no-in-group']
        let choiceCurrentPlaceNumber = event.source.$attrs['data-choice-current-place-no']

        if (placeGroup !== choiceGroup) {
            return
        }

        let question: DragDropImageOrTextQuestion | undefined = this.quizQuestions.find((question: DragDropImageOrTextQuestion) => {
            return question.id === questionId
        })

        if (typeof question === 'undefined') {
            return
        }

        let place: Place | undefined = question.places.find((place: Place) => {
            return place.no === placeNumber
        })

        if (typeof place === 'undefined') {
            return
        }

        let choice: Choice | undefined = undefined
        for (let choiceGroupItem of question.choices) {
            choice = choiceGroupItem.choices.find((choice: Choice) => {
                return choice.group === choiceGroup && choice.no_in_group === choiceNumberInGroup
            })
            if (choice && typeof choice !== 'undefined') {
                break
            }
        }

        if (typeof choice === 'undefined') {
            return
        }

        // Remove choice from current place if choice is moved to another place
        if (choiceCurrentPlaceNumber > 0) {
            let currentPlace: Place | undefined = question.places.find((place: Place) => {
                return place.no === choiceCurrentPlaceNumber
            })

            if (typeof currentPlace === 'undefined') {
                return
            }

            currentPlace.selected_choice = 0
        }

        // Remove current choice of the selected place
        if (place.selected_choice > 0) {
            let oldChoice: Choice | undefined = undefined
            for (let choiceGroupItem of question.choices) {
                oldChoice = choiceGroupItem.choices.find((choice: Choice) => {
                    return choice.group === choiceGroup && choice.no_in_group === place?.selected_choice
                })
                if (oldChoice && typeof oldChoice !== 'undefined') {
                    place.selected_choice = 0
                    oldChoice.is_dragged = false
                    break
                }
            }
        }

        // Add new choice to the selected place
        place.selected_choice = choiceNumberInGroup
        choice.is_dragged = true
    }

    isChoiceDraggedByPlace(place: Place, choices: ChoiceGroup[]) {
        for (let choiceGroup of choices) {
            let choice: Choice | undefined = choiceGroup.choices.find((choice: Choice) => {
                return choice.group === place.group && choice.no_in_group === place.selected_choice
            })
            if (choice && typeof choice !== 'undefined') {
                return choice.is_dragged
            }
        }
        return false
    }

    getChoiceByPlace(place: Place, choices: ChoiceGroup[]): Choice | undefined {
        for (let choiceGroup of choices) {
            let choice: Choice | undefined = choiceGroup.choices.find((choice: Choice) => {
                return choice.group === place.group && choice.no_in_group === place.selected_choice
            })
            if (choice && typeof choice !== 'undefined') {
                return choice
            }
        }
        return undefined
    }

    getHtmlContentForPlace(place: Place, choices: ChoiceGroup[]): string {
        if (place.selected_choice === 0) {
            return ''
        }

        for (let choiceGroup of choices) {
            let choice: Choice | undefined = choiceGroup.choices.find((choice: Choice) => {
                return choice.group === place.group && choice.no_in_group === place.selected_choice
            })
            if (choice && typeof choice !== 'undefined') {
                if (choice.image) {
                    return '<div class="dragDropImage__draggable"><img v-if="' + choice.image + '" src="' + choice.image.fileUrl + '" alt="choice image" class="d-inline-block" /></div>'
                } else {
                    return '<div class="dragDropImage__draggable"><span v-else class="body-2">' + choice.text + '</span></div>'
                }
            }
        }

        return ''
    }

    resizeAllDragsAndDrops(): number {
        return Math.round(this.maxLengthTextChoice * 8.5)
    }
}
